<template>
  <main class="main">
    <div class="container">
      <div class="main__inner main__inner">
        <section class="section section__feedback-appeal">
          <section class="mb32">
            <h2>Направить официальное обращение</h2>
            <p class="appeal__text">
              В данном разделе вы можете отправить официальное обращение в орган власти города Махачкалы.
              Пожалуйста, прежде чем отправить обращение в форме электронного документа, внимательно
              ознакомьтесь со <a class="link__highlighted" href="#" target="_blank">следующей информацией</a>.
            </p>
          </section>
          <form @submit.prevent="submitForm">
            <section class="mb32">
              <h3>Я хочу обратиться в орган власти:</h3>
              <div class="form__items">
                <div class="form__item">
                  <label class="form__item-title">
                    <span class="required__symbol">*</span> Подразделение администрации
                  </label>
                  <div>
                    <Multiselect
                      track-by="id"
                      label="title"
                      v-model="form.department_id.value"
                      :options="[]"
                      placeholder="Выберите из списка"
                      selectLabel="Выбрать ↵"
                      deselectLabel="Удалить ↵"
                      selectedLabel="Выбрано"
                      :searchable="true"
                      :allow-empty="true"
                      disabled
                    >
                      <span slot="noOptions">Список пуст</span>
                      <span slot="noResult">Ничего не найдено.</span>
                    </Multiselect>
                    <span class="input__error" role="alert" v-if="form.department_id.message">{{
                        form.department_id.message
                      }}</span>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__item-title">Должностное лицо</label>
                  <div>
                    <Multiselect
                      track-by="id"
                      label="name"
                      v-model="form.minister_id.value"
                      :options="[]"
                      placeholder="Выберите из списка"
                      selectLabel="Выбрать ↵"
                      deselectLabel="Удалить ↵"
                      selectedLabel="Выбрано"
                      :searchable="true"
                      :allow-empty="true"
                      disabled
                    >
                      <div slot="option" slot-scope="item">
                        {{ item.option.surname }} {{ item.option.name }} {{ item.option.patronymic }}
                      </div>
                      <span slot="noOptions">Список пуст</span>
                      <span slot="noResult">Ничего не найдено.</span>
                    </Multiselect>
                    <span class="input__error" role="alert" v-if="form.minister_id.message">{{
                        form.minister_id.message
                      }}</span>
                  </div>
                </div>
              </div>
            </section>
            <section class="mb32">
              <h3>Мои сведения:</h3>
              <div class="form__items">
                <div class="form__item form__item-radio">
                  <label class="form__item-title"> Я обращаюсь от имени </label>
                  <div>
                    <label @click="form.name_organization.value = null" for="individual" class="radio__label">
                      <input
                        v-model="form.type_id.value"
                        type="radio"
                        id="individual"
                        name="type_id"
                        :value="$store.state.type.CODE_INDIVIDUAL"
                      />
                      Физического лица
                    </label>
                    <label for="entity" class="radio__label">
                      <input
                        v-model="form.type_id.value"
                        type="radio"
                        id="entity"
                        name="type_id"
                        :value="$store.state.type.CODE_ENTITY"
                      />
                      Юридического лица
                    </label>
                    <span class="input__error" role="alert" v-if="form.type_id.message">{{
                      form.type_id.message
                    }}</span>
                  </div>
                </div>
                <div v-show="form.type_id.value === $store.state.type.CODE_ENTITY" class="form__item">
                  <label class="form__item-title" for="org">
                    <span class="required__symbol">*</span> Название организации
                  </label>
                  <div>
                    <input
                      v-model="form.name_organization.value"
                      type="text"
                      id="org"
                      placeholder="Введите название организации"
                    />
                    <span class="input__error" role="alert" v-if="form.name_organization.message">{{
                        form.name_organization.message
                      }}</span>
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__item-title" for="fio">
                    <span class="required__symbol">*</span> Фамилия, Имя, Отчество
                  </label>
                  <div>
                    <input v-model="form.fio.value" type="text" id="fio" placeholder="Иванов Иван Иванович" />
                  </div>
                </div>
                <div class="form__item">
                  <label class="form__item-title" for="phone">
                    <span class="required__symbol">*</span> Контактный телефон
                  </label>
                  <div>
                    <input
                      v-model="form.phone.value"
                      type="text"
                      id="phone"
                      v-mask="'+7 (###) ###-##-##'"
                      placeholder="+7 (928) 123-45-67"
                    />
                    <span class="input__error" role="alert" v-if="form.phone.message">{{
                        form.phone.message
                      }}</span>
                  </div>
                </div>
                <div class="form__item">
                  <label for="email" class="form__item-title">
                    <span class="required__symbol">*</span> Адрес электронной почты
                  </label>
                  <div>
                    <input
                      v-model="form.email.value"
                      type="email"
                      id="email"
                      placeholder="example@email.com"
                    />
                    <span class="input__error" role="alert" v-if="form.email.message">{{
                        form.email.message
                      }}</span>
                  </div>
                </div>
                <div class="form__item">
                  <label for="post_address" class="form__item-title">
                    <span class="required__symbol">*</span> Почтовый адрес
                  </label>
                  <div>
                    <input
                      v-model="form.address.value"
                      type="text"
                      id="post_address"
                      placeholder="г. Махачкала, ул. Центральная, 1"
                    />
                    <span class="input__error" role="alert" v-if="form.address.message">{{
                        form.address.message
                      }}</span>
                  </div>
                </div>
              </div>
            </section>
            <section class="mb32">
              <h3>Содержание обращения</h3>
              <p class="appeal__text">
                Рекомендуем указать в тексте обращения суть предложения (заявления, жалобы) и адрес описанного
                вами места действия, факта или события (при его наличии). Ответ по сути обращения не даётся в
                случае, если в обращении отсутствует текст, даны только ссылки на прилагаемые файлы или адреса
                интернет-сайтов
              </p>
              <div class="form__item form__item-textarea form__item-full">
                <label for="text"><span class="required__symbol">*</span> Текст обращения</label>
                <textarea v-model="form.content.value" id="text" rows="4"></textarea>
                <span class="input__error" role="alert" v-if="form.content.message">{{
                  form.content.message
                }}</span>
              </div>
              <div class="appeal__doc-text appeal__text">
                В качестве подтверждения своих доводов к письменному обращению прилагаю документы и материалы,
                либо их копии.
                <br />
                <span>
                  Файл в формате .doc, .docx, .pdf или jpg. Не более 3 файлов, максимальный размер каждого —
                  5 МБ
                </span>
              </div>

              <div
                v-if="form.documents_arr.value && form.documents_arr.value.length"
                class="appeal__docs-list"
              >
                <div v-for="(item, index) in form.documents_arr.value" :key="index" class="appeal__docs-item">
                  <DocumentIcon />
                  <div class="appeal__doc-info">
                    <div class="appeal__doc-title">{{ fileName(item.name) }}</div>
                    <div class="appeal__doc-size">
                      <span>{{ fileType(item.name) }}</span> ({{ fileSizeFormat(item.size) }})
                    </div>
                  </div>
                  <a @click.prevent="removeFile(index)" href="#">
                    <DeleteIcon />
                  </a>
                </div>
              </div>

              <input
                @change="handleFiles"
                type="file"
                accept="image/jpeg, .doc, .docx, .pdf"
                ref="docsInput"
                multiple
                class="hidden"
              />
              <button @click="addDocument" class="btn-transpar_blue_dark btn--small" type="button">
                Добавить документ
              </button>
            </section>
            <section class="mb32">
              <div class="form__item form__item-checkbox terms-accept__item form__item-full">
                <label for="agree" class="checkbox__label">
                  <input v-model="form.agree.value" id="agree" type="checkbox" required />
                  <span>
                    Я принимаю условия&nbsp;
                    <a href="#" class="link__highlighted" target="_blank">
                      соглашения о пользовании информационными системами и ресурсами города Махачкалы
                    </a>
                  </span>
                </label>
              </div>
              <button v-if="loading" class="btn-blue_dark btn--small">
                <LoadingIndicator title="Загрузка" />
              </button>
              <button v-else class="btn-blue_dark btn--small" type="submit">Отправить</button>
              <p class="explanation__text appeal__text">
                <span class="required__symbol">*</span> Поля отмеченные звёздочкой обязательны для заполнения
              </p>
            </section>
          </form>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import Multiselect from 'vue-multiselect'
import DocumentIcon from '@/components/svg/DocumentIcon.vue'
import DeleteIcon from '@/components/svg/DeleteIcon.vue'
import LoadingIndicator from 'components/LoadingIndicator.vue'
import THIS_DEPARTMENT from '@/graphql/department/thisDepartment.graphql'

export default {
  name: 'OfficialAppealPage',
  // async asyncData({ store }) {
  //   await store.dispatch("GET_APPEAL_PAGE");
  // },
  data () {
    return {
      loading: false,
      administration: {
        id: null,
        title: 'Администрация'
      },
      form: {
        department_id: {
          value: null,
          message: null
        },
        minister_id: {
          value: null,
          message: null
        },
        type_id: {
          value: this.$store.state.type.CODE_INDIVIDUAL,
          message: null
        },
        fio: {
          value: null,
          message: null
        },
        phone: {
          value: null,
          message: null
        },
        email: {
          value: null,
          message: null
        },
        post_address: {
          value: null,
          message: null
        },
        name_organization: {
          value: null,
          message: null
        },
        address: {
          value: null,
          message: null
        },
        content: {
          value: null,
          message: null
        },
        documents_arr: {
          value: [],
          message: null
        },
        agree: {
          value: null,
          message: null
        }
      }
    }
  },
  created () {
    this.$apollo.provider.clients.dept.query({
      query: THIS_DEPARTMENT
    })
      .then(({ data }) => {
        this.pageLoading = false
        if (data.this_department) {
          this.$store.state.this_department = data.this_department
          this.setDefaultValues()
        }
      })
      .catch((err) => {
        this.pageLoading = false
        console.log(err)
      })
  },
  computed: {
    department () {
      return this.$store.state.this_department
    },
    ministers () {
      const arr = []
      arr.push(this.$store.state.this_department.leader)
      return arr
    }
  },
  methods: {
    setDefaultValues () {
      this.form.department_id.value = this.department
      this.form.minister_id.value = this.department.leader
      this.form.minister_id.value.name = this.department.leader.title
      this.form.type_id.value = this.$store.state.type.CODE_INDIVIDUAL
    },
    addDocument () {
      this.$refs.docsInput.click()
    },
    handleFiles () {
      const files = this.$refs.docsInput.files
      for (let i = 0; i < files.length; i++) {
        this.form.documents_arr.value.push(files[i])
      }
      this.$refs.docsInput.value = null
    },
    fileName (name) {
      return name.slice(0, name.lastIndexOf('.'))
    },
    fileSizeFormat (size) {
      if (size < 1024) {
        return size + ' Б'
      }
      if (size < 1048576) {
        return Math.ceil(size / 1024) + ' КБ'
      }
      return (size / 1048576).toFixed(2) + ' МБ'
    },
    fileType (name) {
      return name.slice(name.lastIndexOf('.') + 1, name.length)
    },
    removeFile (i) {
      this.form.documents_arr.value.splice(i, 1)
    },
    submitForm () {
      if (!this.loading) {
        this.loading = true
        this.resetValidation()
        const fd = new FormData()
        /**
         * Сбор всех полей формы и закрепление в formData
         */
        if (this.form.agree.value) {
          Object.keys(this.form).forEach((key) => {
            if (key === 'fio') {
              // 1 слово фамилия, 2 - имя, остальное отчество
              if (this.form[key].value) {
                const fioArr = this.form[key].value.split(' ')
                if (fioArr[0]) fd.append('surname', fioArr[0])
                if (fioArr[1]) fd.append('name', fioArr[1])
                if (fioArr[2]) fd.append('patronymic', fioArr.slice(2).join(' '))
              }
            } else if (key === 'department_id' || key === 'minister_id') {
              if (this.form[key].value && this.form[key].value.id) fd.append(key, this.form[key].value.id)
            } else if (key === 'documents_arr') {
              if (this.form[key].value && this.form[key].value.length) {
                this.form[key].value.forEach((item, i) => {
                  fd.append(`documents_arr[${i}][title]`, item.name)
                  fd.append(`documents_arr[${i}][document]`, item)
                  fd.append(`documents_arr[${i}][position]`, i)
                })
              }
            } else {
              if (this.form[key].value !== null) fd.append(key, this.form[key].value)
            }
          })

          this.$store
            .dispatch('POST_OFFICIAL_APPEAL', {
              token: localStorage.getItem('apollo-token'),
              data: fd
            })
            .then(() => {
              this.loading = false
              // очищение полей формы, кроме департамента и министра
              Object.keys(this.form).forEach((field) => {
                if (field === 'documents_arr') {
                  this.form[field].value = []
                } else {
                  this.form[field].value = null
                }
              })
              this.setDefaultValues()
              window.scrollTo(0, 0)
              this.$notify({
                title: 'Успешно',
                text: 'Ваше обращение успешно отправлено',
                type: 'success'
              })
            })
            .catch(({ response }) => {
              this.loading = false
              this.$notify({
                title: 'Ошибка',
                text: 'Произошла ошибка, проверьте введенные данные',
                type: 'error'
              })
              // Вывод ошибок валидации от Laravel
              Object.keys(response.data).forEach((message) => {
                Object.keys(this.form).forEach((field) => {
                  if (field === message) {
                    this.form[field].message = response.data[message][0]
                  }
                })
              })
            })
        } else {
          this.form.agree.message = 'Поставьте, пожалуйста, галочку'
        }
      }
    },
    /**
     * Сброс сообщений от валидации laravel
     */
    resetValidation () {
      Object.keys(this.form).forEach((field) => {
        this.form[field].message = null
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.$store.state.appeal_page = {}
    next()
  },
  metaInfo () {
    return this.$seo(
      'common',
      'Официальный сайт Главы города Махачкалы',
      '',
      '',
      'Официальный сайт Главы города Махачкалы',
      '',
      ''
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  },
  components: {
    Multiselect,
    DocumentIcon,
    DeleteIcon,
    LoadingIndicator
  }
}
</script>

<style lang="stylus">
@import '~vue-multiselect/dist/vue-multiselect.min.css'

.section__feedback-appeal {
  section:last-child {
    margin-bottom: 0;
  }

  h3 {
    margin-bottom: 16px;
    +below(768px) {
      font-size 1.125em
      line-height 24px
    }
    +below(380px) {
      font-size 1em
      line-height 20px
    }
  }

  .form__item-textarea {
    margin-bottom: 16px;
  }

  .terms-accept__item {
    display flex
    padding 0 12px
    margin-bottom: 16px;
    +below(540px) {
      padding 0
    }

    label {
      display flex
      align-items flex-start
    }
  }

  .explanation__text {
    margin-top: 16px;
  }
}

.appeal {
  &__text {
    +below(380px) {
      font-size 0.875em
      line-height 20px
    }
  }

  &__doc-text {
    color var(--color_gray_dark)
    margin-bottom: 16px;
  }

  &__docs-item {
    display flex
    align-items flex-start
    margin-bottom: 16px;
  }

  &__doc-info {
    flex 0 0 492px
    margin 0 24px
    +below(768px) {
      flex 1
    }
    +below(480px) {
      margin 0 16px
    }
  }

  &__doc-title {
    color var(--color_blue_dark)
    display block
    margin-bottom: 4px;
    word-break break-all
  }

  &__doc-size {
    font-size: 0.875em
    line-height: 20px;
    color var(--color_gray_dark)

    span {
      text-transform: uppercase;
    }
  }
}
</style>
