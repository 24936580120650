<template>
  <i class="icon-medium">
    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5 15H15C15.3978 15 15.7794 14.842 16.0607 14.5607C16.342 14.2794 16.5 13.8978 16.5 13.5C16.5 13.1022 16.342 12.7206 16.0607 12.4393C15.7794 12.158 15.3978 12 15 12H13.5C13.1022 12 12.7206 12.158 12.4393 12.4393C12.158 12.7206 12 13.1022 12 13.5C12 13.8978 12.158 14.2794 12.4393 14.5607C12.7206 14.842 13.1022 15 13.5 15ZM13.5 18C13.1022 18 12.7206 18.158 12.4393 18.4393C12.158 18.7206 12 19.1022 12 19.5C12 19.8978 12.158 20.2794 12.4393 20.5607C12.7206 20.842 13.1022 21 13.5 21H22.5C22.8978 21 23.2794 20.842 23.5607 20.5607C23.842 20.2794 24 19.8978 24 19.5C24 19.1022 23.842 18.7206 23.5607 18.4393C23.2794 18.158 22.8978 18 22.5 18H13.5ZM30 13.41C29.9844 13.2722 29.9542 13.1364 29.91 13.005V12.87C29.8379 12.7158 29.7417 12.574 29.625 12.45L20.625 3.45C20.501 3.33332 20.3592 3.23712 20.205 3.165C20.1602 3.15864 20.1148 3.15864 20.07 3.165C19.9176 3.07761 19.7493 3.02152 19.575 3H10.5C9.30653 3 8.16193 3.47411 7.31802 4.31802C6.47411 5.16193 6 6.30653 6 7.5V28.5C6 29.6935 6.47411 30.8381 7.31802 31.682C8.16193 32.5259 9.30653 33 10.5 33H25.5C26.6935 33 27.8381 32.5259 28.682 31.682C29.5259 30.8381 30 29.6935 30 28.5V13.5C30 13.5 30 13.5 30 13.41ZM21 8.115L24.885 12H22.5C22.1022 12 21.7206 11.842 21.4393 11.5607C21.158 11.2794 21 10.8978 21 10.5V8.115ZM27 28.5C27 28.8978 26.842 29.2794 26.5607 29.5607C26.2794 29.842 25.8978 30 25.5 30H10.5C10.1022 30 9.72064 29.842 9.43934 29.5607C9.15804 29.2794 9 28.8978 9 28.5V7.5C9 7.10218 9.15804 6.72064 9.43934 6.43934C9.72064 6.15804 10.1022 6 10.5 6H18V10.5C18 11.6935 18.4741 12.8381 19.318 13.682C20.1619 14.5259 21.3065 15 22.5 15H27V28.5ZM22.5 24H13.5C13.1022 24 12.7206 24.158 12.4393 24.4393C12.158 24.7206 12 25.1022 12 25.5C12 25.8978 12.158 26.2794 12.4393 26.5607C12.7206 26.842 13.1022 27 13.5 27H22.5C22.8978 27 23.2794 26.842 23.5607 26.5607C23.842 26.2794 24 25.8978 24 25.5C24 25.1022 23.842 24.7206 23.5607 24.4393C23.2794 24.158 22.8978 24 22.5 24Z"
        fill="#A7A8AB"
      />
    </svg>
  </i>
</template>

<script>
export default {
  name: 'DocumentIcon'
}
</script>
